<template>
  <div class="maintenance" id="maintenance">
    <div class="ui grid container faq">
      <div class="intro-content">
        <p class="path">Frequently Asked Questions > Account Maintenance/Management</p>
        <h3><span>Account</span> Maintenance/Management</h3>

        <h4 id="accmm1">How do I access my account profile?</h4>
        <div class="paragraph">
          <p>
            In your TayoCash app, tap the <span class="vtxt-align">Profile</span> icon at the top left corner of your home screen. Your Profile gives you an overview of your account and gives you access to other Account and Support features.
          </p>
        </div>

        <h4 id="accmm2">How can I update my profile?</h4>
        <div class="paragraph">
          <p>To update or correct your profile information, kindly contact our Customer Care Group at <span class="vtxt-align">8888-TAYO</span> or send an email to <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>.</p>
        </div>

        <h4 id="accmm3">What does my badge in my Dashboard indicate?</h4>
        <div class="paragraph">
          <p>
            Your badge indicates your membership status - whether as Regular, Partner, or Merchant, depending on whether you are a Member (Regular), TayoCash Partner or Merchant. Your badge also shows the basic information of your TayoCash wallet such as your name and current and available wallet balance.
          </p>
        </div>

        <h4 id="accmm4">Where can I see my verification status?</h4>
        <div class="paragraph">
          <p>Apart from the badge shown in your Dashboard, you may tap the <span class="vtxt-align">Profile</span> icon found at the top left corner of your screen. The bar under your name shows your verification status.</p>
        </div>

        <h4 id="accmm5">Where can I view my current and available wallet balance?</h4>
        <div class="paragraph">
          <p>
            You can view your TayoCash Wallet balance in the Dashboard in your app home screen.
          </p>
        </div>

        <h4 id="accmm6">How do I change my security PIN?</h4>
        <div class="paragraph">
          <ol>
            <li class="nomargin">Open your TayoCash app and tap the <span class="vtxt-align">Profile</span> icon found at the top left corner of your screen.</li>
            <li class="nomargin">Go to <span class="vtxt-align">Account Maintenance</span> and look for <span class="vtxt-align">Change 4-digit PIN</span>.
            <li class="nomargin">Confirm by clicking <span class="vtxt-align">Yes</span>.</li>
            <li class="nomargin">Enter your current PIN.</li>
            <li class="nomargin">Enroll and re-enter your new PIN.</li>
          </ol>
        </div>

        <h4 id="accmm7">I forgot my PIN. How can I reset it?</h4>
        <div class="paragraph">
          <ol>
            <li class="nomargin">Click <span class="vtxt-align">Forgot PIN</span> at the log-in page of the app.</li>
            <li class="nomargin">Answer security questions to validate your identity. A Customer Care Representative will call you, if necessary.</li>
            <li class="nomargin">Once verification is successful, you will receive an SMS containing a temporary PIN you will use to log in.</li>
            <li class="nomargin">You will be prompted to enroll a new PIN.</li>
          </ol>
        </div>

        <h4 id="accmm8">I cannot log in to my account because I am not receiving a One-Time PIN (OTP). What can I do?</h4>
        <div class="paragraph">
          <p class="nomargin">If you did not receive your OTP, kindly check the following first:</p>
          <ol class="nomargin">
            <li class="nomargin">If you have good signal reception to receive SMS.</li>
            <li>If your network provider has any issue in receiving SMS/text messages within your area.</li>
          </ol>

          <p class="nomargin">If you need further assistance with your OTP, you may either:</p>
          <ol class="nomargin">
            <li>
              <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">Submit a ticket</router-link>
               via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website:
              <ul class="loweralpha">
                <li>Select <span class="vtxt-align">TayoCash OTP</span></li>
                <li>Input the following details in the text field:
                  <ul>
                    <li>TayoCash registered mobile number</li>
                    <li>Details of the concern</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>Send us an email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>:
              <ul class="loweralpha">
                <li>Email Subject: TayoCash OTP  Issue</li>
                <li>Email Content:
                  <ul>
                    <li>TayoCash registered mobile number</li>
                    <li>Details of the concern</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
          <p>A TayoCash Customer Care Representative will get back to you within <span class="vtxt-align">48 hours</span> after you have submitted the ticket.</p>
        </div>

        <h4 id="accmm9">Where can I view my Transaction History?</h4>
        <div class="paragraph">
          <p>
            You can view your transaction history by tapping the <span class="vtxt-align">Transactions</span> icon in your app. You can view your most recent transactions in this tab. You may request for the complete Transaction History via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website or send us an email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>.
          </p>
        </div>

        <h4 id="accmm10">I have not received the official receipt/acknowledgement receipt for my transaction. How do I request a copy of it?</h4>
        <div class="paragraph">
          <p class="nomargin">
            To request a copy of your transaction official receipt/acknowledgement receipt, you may either:
          </p>
          <ol class="nomargin">
            <li>
             <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">Submit a ticket</router-link> via the TayoCash <a class="link" href="/helpcenter"> Help Center </a>
               in the app or website:
              <ul class="loweralpha">
                <li>Select <span class="vtxt-align">TayoCash OR</span> or <span class="vtxt-align">TayoCash AR</span></li>
                <li>Input the following details in the text field:
                  <ul>
                    <li>TayoCash registered mobile number</li>
                    <li>Transaction Reference Number</li>
                    <li>Type of receipt needed</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Send us an email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>:
              <ul class="loweralpha">
                <li>Email Subject: TayoCash OR or TayoCash AR  Issue</li>
                <li>Email Content:
                  <ul>
                    <li>TayoCash registered mobile number</li>
                    <li>Transaction Reference Number</li>
                    <li>Type of receipt needed</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
          <p>
            A TayoCash Customer Care Representative will get back to you within <span class="vtxt-align">48 hours</span> after you have submitted the ticket.
          </p>
        </div>

        <h4 id="accmm11">How do I turn off my app notifications?</h4>
        <div class="paragraph">
          <p>
            It is recommended to have your notifications on for you to be updated on all your TayoCash transactions and promotions. But you may still choose to disable notifications by following the steps below:
          </p>
          <ol>
            <li class="nomargin">Open your TayoCash app and tap the <span class="vtxt-align">Profile</span> icon found at the top left corner of your screen.</li>
            <li class="nomargin">Go to <span class="vtxt-align">Account Maintenance</span> and look for <span class="vtxt-align">Notifications</span>.</li>
            <li class="nomargin">Toggle the Notifications bar to disable.</li>
          </ol>
        </div>

        <h4 id="accmm12">How do I update my phone number?</h4>
        <div class="paragraph">
          <ol>
            <li class="nomargin">Open your TayoCash app and tap the <span class="vtxt-align">Profile</span> icon found at the top left corner of your screen.</li>
            <li class="nomargin"> Go to <span class="vtxt-align">Account Maintenance</span> and look for <span class="vtxt-align">Update Phone Number</span>.</li>
            <li class="nomargin">Tap <span class="vtxt-align">Yes</span> on the prompt confirming you wish to update your phone number.</li>
            <li class="nomargin">Enter your PIN.</li>
            <li class="nomargin">Enter your new mobile number.</li>
            <li class="nomargin">Enter the OTP sent to your new mobile number.</li>
            <li class="nomargin">Press <span class="vtxt-align">Confirm</span>.</li>
          </ol>
        </div>

        <h4 id="accmm13">Should I use my phone’s device authentication settings for TayoCash?</h4>
        <div class="paragraph">
          <p>
            It is recommended that you apply this feature to add to the security of your account. When you download the app, it will prompt you to enable device authentication.
          </p>
          <p>
            If you skipped this step, you may go to your <span class="vtxt-align">Profile</span> and to your <span class="vtxt-align">Account Settings</span> and toggle right to enable authentication.
          </p>
        </div>

        <h4 id="accmm14">Is there a limit to the amount of money my Tayocash wallet can hold?</h4>
        <div class="paragraph">
          <p>
            Wallet limits shall vary and depend on the type of verification a member undergoes. Individual members have the option to register for a Basic, Semi-Verified, or Fully Verified Account.
          </p>
        </div>

        <div class="buttons">
          <a href="/faq/disputes" class="yellow">BACK</a>
          <a href="/faq/deactivation" class="white">NEXT</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'maintenance'
}
</script>

<style scoped lang='scss'>
</style>
